
// import CollectionPage from '../components/collection_page';
import React from 'react';
import { Container, Row } from 'styled-bootstrap-grid2'
import Container3Col from '../components/container_3col'
import ProductListManualCollection from '../components/product_list_manual_collection'
import SEO from '../components/seo'

const ShopPageTemplate = (props) => {
  // console.log('the props', props);
  return (
    <div>
    <SEO title="Shop" keywords={[`Shop`, `Jewellery`, props.pageContext.name, `Theodora Warre`]} />
      <Container3Col longpage top>
        <Container>
          <Row>
            <ProductListManualCollection category={props.pageContext.filter} />
          </Row>
        </Container>
      </Container3Col>
    </div>
  )
};

export default ShopPageTemplate;
